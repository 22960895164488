import { Component } from "react";
import Container from "react-bootstrap/esm/Container";

import { Row, Col} from 'react-bootstrap';

import axios from "axios";
import {getGlobalState } from './App';
import EvaluationCard from "./evaluationCard";


class Evaluations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courseCode : getGlobalState("coursecode"),
      links: [],
      titles: [],
      length: 0
    }
  }

  componentDidMount() {
    console.log(this.state.courseCode);
    axios.get(`https://us-central1-nhsbible-70f07.cloudfunctions.net/tempevals?courseCode=${this.state.courseCode}`)
    .then(response => {
      const responseTitles = response.data.result.testNames;
      this.setState({titles: responseTitles});
      const responselinks = response.data.result.downloadUrls;
      this.setState({links: responselinks});
      this.setState({length: response.data.result.downloadUrls.length});
      console.log(this.state.links);
      console.log(this.state.titles);
    }).then(nothing => {})

    
  }
  render() {
    return (
      <div>
          <Container>
          <Row>
            
            {this.state.titles.map((title, index) => (
              <Col xs={12} sm={3} lg={3}>
              <EvaluationCard testName={title}  evaluationLink={this.state.links[index]}/>
              </Col>
    ))}
            
              {/* <Col>
              <h1>{this.state.courseCode}</h1>
        </Col> */}
        </Row>
        </Container>
      </div>
    );
  }
}






export default Evaluations;