import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button'
import calcandvectors from './calcandvectors.jpg';
import { Link } from 'react-router-dom';
import { setGlobalState, getGlobalState } from './App';
import physicsbook from './physics12.jpeg'
import gradetwelvechemistrytextbook from './sch4utextbook.jpeg';



// function Photoportion(props) {
//   if (props.photo === "calcandvectors") {
//     <Card.Img variant="top" src={calcandvectors} />
//   } else {
//     return <Card.Img variant="top" src={physicsbook} />
//   }
// }


function setcodestate(code) {
  setGlobalState("coursecode", code);
  console.log(getGlobalState("coursecode"));
}

function Mathcard(props) {
  
    return (
<Card className="bg-light text-black">
  {/* <h1>{useGlobalState("coursecode")}</h1> */}
  {/* <Photoportion photo={props.photo} /> */}
  {props.course === "mcv4u" &&
        <Card.Img variant="top" src={calcandvectors}  className="min-vh-100"/>

        
      }

{props.course === "sph4u" &&
        <Card.Img variant="top" src={physicsbook} className="min-vh-100"/>
      } 

{props.course === "sch4u" &&
        <Card.Img variant="top" src={gradetwelvechemistrytextbook} className="min-vh-100"/>
      } 


  <Card.Body>
  <Card.Title>{props.course}</Card.Title>
    <Card.Title>{props.teacher}</Card.Title>
    {/* <Card.Title>{props.coursecode}</Card.Title> */}
    <Link to="/evaluations"><Button variant="primary" onClick={() => setcodestate(props.coursecode)}>View Evaluations</Button></Link>
    {/* <Button variant="primary">View Homework</Button> */}
  </Card.Body>
</Card>
    );
}

export default Mathcard;