
import './App.css';
import NavigationBar from './header';
import Select from './homepage';
import Evaluations from './evaluations';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { createGlobalState } from 'react-hooks-global-state';



const { setGlobalState, useGlobalState, getGlobalState } = createGlobalState({
  coursecode: "none"
});
function App() {
  return (
    <div>
      
      <BrowserRouter>
      {/* <Tempalert /> */}
      <NavigationBar />
    <Routes>
      <Route path="/" element={<Select />} />
      <Route path="evaluations" element={<Evaluations />} />
     
    </Routes>
  </BrowserRouter>


      {/* <BrowserRouter>
    
    <Select />
    <Evaluations />
    </BrowserRouter> */}
    </div>
  );
}

export default App;
export {useGlobalState, setGlobalState, getGlobalState};