import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import Tempalert from './alert';

function NavigationBar() {
    return (
      <div>
        <Tempalert />
        <Navbar bg="dark" variant="dark">
        <Container>
        <Link to="/"><Navbar.Brand>NHSBible</Navbar.Brand></Link>
        <Nav className="me-auto">
          {/* <Nav.Link>Home</Nav.Link>
          <Nav.Link>About</Nav.Link> */}
        </Nav>
        </Container>
      </Navbar>
      </div>
    );
}

export default NavigationBar;