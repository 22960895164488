import { Component } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './App.css';

import Container from 'react-bootstrap/Container';

import Mathcard from "./mathCard";

import axios from "axios";
// import Link from 'react-bootstrap/Link';
class Select extends Component {
    constructor(props) {
      super(props);
      this.state = {
        courses: [],
        teachers: [],
        coursecodes: [],
        pictures: [],
      }
    }

    componentDidMount() {
      // console.log("yeah that's that thug shaker");
      axios.get(`https://us-central1-nhsbible-70f07.cloudfunctions.net/homepage`)
      .then(response => {
        console.log(response.data);
        const responseCourses = response.data.course;
        const responseteachers = response.data.teacher;
        const responsepictures = response.data.picture;
        const responsecoursecodes = response.data.coursecode;
        this.setState({courses: responseCourses});
        this.setState({teachers: responseteachers});
        this.setState({pictures: responsepictures});
        this.setState({coursecodes: responsecoursecodes});
        // console.log(responsepictures[0])
        console.log(this.state.picture[0]);
      }).then(nothing => {})
    }


    

    render (){
        return (
<Container className="blueBackground">

{/* {
          this.state.courses
            .map((courseIterator) =>
            <div>
              <h1 key={courseIterator.course}>{courseIterator.course}</h1>
              <h2 key = {courseIterator.course}>{courseIterator.teachers + " "}</h2>
              </div>
            )
        } */}

      
        <Row className="mainContainer">
          {/* <Link to="/savedWorkouts"> */}

          {this.state.courses.map((course, index) => (
            
<Col xs={12} sm={4} lg={4}>
          <Mathcard key={index} course = {course} teacher = {this.state.teachers[index]}  coursecode= {this.state.coursecodes[index]}/>
          </Col>
    ))}

          

          {/* </Link> */}

          {/* <Col xs={12} sm={4} lg={4}>
            <Mathcard course = "MCV4U" teacher = "Ms.Novotny" photo = {calcandvectors} coursecode= "mcv4unovotny "/>
          </Col>
          <Col xs={12} sm={4} lg={4}>
            <Mathcard course = "MCV4U" teacher = "Test" photo = {calcandvectors} coursecode= "courseTest"/>
          </Col>
          <Col xs={12} sm={4} lg={4}>
          <Mathcard course = "MCV4U" teacher = "Ms.Amimi" photo = {calcandvectors} coursecode= "mcv4uamimi"/>
          </Col>
          <Col xs={12} sm={4} lg={4}>
          <Mathcard course = "SPH4U" teacher = "Mr.Roberts" photo = {physicsbook} coursecode = "sph4uroberts"/>
          </Col>
          <Col xs={12} sm={4} lg={4}>
          <Mathcard course = "SPH4U" teacher = "Mr.Gragg" photo = {physicsbook} coursecode = "sph4ugragg"/>
          </Col> */}
        </Row>
      </Container>
        );
}
}

export default Select;