
import './App.css';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';


function EvaluationCard(props) {
    return (
        <Container>
            <Card style={{ width: '18rem' }}>
  <Card.Body>
    <Card.Title>{props.testName}</Card.Title>

    <a href ={props.evaluationLink}><Button variant="primary">View PDF</Button> </a>
  
  </Card.Body>
</Card>
        </Container>
    );
}


export default EvaluationCard;